<template>
    <tr v-if="hideDetail == false" @click="showDetailPolozky">
        <td :colspan="cs">
            <div class="table-content">
                <div class="table-wrapper">
                    <table class="table table-bordered-dark table-striped-dark">
                        <thead>
                        <tr class="text-center">
                            <th>Číslo položky</th>
                            <th>Nová / použitá</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, id) in skladoveZasoby" :class="row.barva_textu">
                                <td class="text-center">{{ row.skladove_cislo }}</td>
                                <td class="text-center">{{ row.typ_polozky }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import axiosApiPostV2 from "@/router/axiosApiMethod";

export default {
    name: "SkladoveZasobyDetailPolNdPolozky",
    data() {
        return {
            skladoveZasoby: {},
            cs: '',
            hideDetail: true
        }
    },
    props: {
        tableCols: '',
        idPolozky: '',
        idSkladu: '',
        idParkovaciPozice: '',
        showDetail: false
    },
    created() {

        this.$root.$on('showDetailPolozky', args => {
            if (args.id_polozky == this.idPolozky)
            {
                if (this.hideDetail == true)
                {
                    this.hideDetail = false;
                }
                else
                {
                    this.hideDetail = true;
                }

                if (this.idPolozky > 0 && (this.idSkladu > 0 || this.idParkovaciPozice > 0) && this.hideDetail == false)
                {
                    this.$root.$emit('showLoader', {show: true});
                    axiosApiPostV2('skladove-zasoby-parkovaci-pozice', {id_skladu: this.idSkladu, id_pozice_regalu: this.idParkovaciPozice, id_polozky: this.idPolozky, detail_polozky: true, vypis_pol_nd_polozek: true}, 'data').then( result => {
                        $.each(result, function (key, value) {
                            value.typ_polozky = 'Nová';
                            value.barva_textu = '';
                            if (value.pouzite == 1)
                            {
                                value.typ_polozky = 'Použitá';
                                value.barva_textu = 'text-success';
                            }
                        });
                        this.skladoveZasoby = result;
                        this.cs = this.tableCols;
                        this.hideDetail = false;
                        this.$root.$emit('showLoader', {show: false});
                    });
                }

            }
        });
    },
    methods: {
        showDetailPolozky()
        {
            if (this.hideDetail == true)
            {
                this.hideDetail = false;
            }
            else
            {
                this.hideDetail = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.skladove-zasoby-detail {
    .table-wrapper {

    }
}
</style>