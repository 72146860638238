<template>
    <div>

        <div class="detail-regalu" v-if="idParkovaciPozice > 0 && zobrazitRegal == true">
            <div class="tisk-stitku">
                <the-button icon="print" circle="false" color="light" size="sm" value="" @button="tiskStitku"/>
            </div>
            <h2 class="text-center">{{ detailParkovaciPozice.kod_pozice }}</h2>
            <div class="obecne-informace">
                <div class="row">
                    <div class="col-6">Pobočka</div>
                    <div class="col-6">{{ detailParkovaciPozice.pobocka }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Sklad</div>
                    <div class="col-6">{{ detailParkovaciPozice.sklad }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Regál</div>
                    <div class="col-6"><span v-html="$options.filters.detailRegalu(detailParkovaciPozice.regal, detailParkovaciPozice.id_regalu)"></span></div>
                </div>
                <div class="row">
                    <div class="col-6">Zkratka regálu</div>
                    <div class="col-6"><span v-html="$options.filters.detailRegalu(detailParkovaciPozice.regal_zkratka, detailParkovaciPozice.id_regalu)"></span></div>
                </div>
            </div>
            <hr>
            <div class="detail-pozice">
                <div class="row">
                    <div class="col-6">Regálové pole</div>
                    <div class="col-6">{{ detailParkovaciPozice.regalove_pole }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Kód pozice</div>
                    <div class="col-6">{{ detailParkovaciPozice.kod_pozice }}</div>
                </div>
                <div class="row">
                    <div class="col-6">Šířka pozice</div>
                    <div class="col-6">{{ detailParkovaciPozice.sirka | formatNumber}} mm</div>
                </div>
                <div class="row">
                    <div class="col-6">Výška pozice</div>
                    <div class="col-6">{{ detailParkovaciPozice.vyska | formatNumber}} mm</div>
                </div>
                <div class="row">
                    <div class="col-6">Hloubka pozice</div>
                    <div class="col-6">{{ detailParkovaciPozice.hloubka | formatNumber}} mm</div>
                </div>
            </div>
            <hr>
            <div class="nosnost">
                <div class="row">
                    <div class="col-6">Nosnost</div>
                    <div class="col-6">{{ detailParkovaciPozice.nosnost | formatNumber}} kg</div>
                </div>
                <div class="row">
                    <div class="col-6">Obsazenost</div>
                    <div class="col-6" :class="zatizenostPozice.barva_textu">{{ zatizenostPozice.obsazenost | formatNumber }} kg</div>
                </div>
                <div class="row">
                    <div class="col-6">Obsazenost</div>
                    <div class="col-6" :class="zatizenostPozice.barva_textu">{{ zatizenostPozice.pomer }} %</div>
                </div>
            </div>
            <hr>

            <div class="top-20">
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-striped table-hover table-vertical">
                            <thead>
                            <tr class="text-center text-small">
                                <th>Náhradní díl</th>
                                <th>Celkem</th>
                                <th>Nové</th>
                                <th>Použité</th>
                            </tr>
                            </thead>
                            <tbody class="text-small">
                            <template v-for="(row, key) in skladoveZasoby">

                                <tr @click="showDetailPolozky(row.id_polozky)">
                                    <td><span v-html="$options.filters.detailPolozky(row.skladove_cislo, row.id_polozky)"></span> <br> <span v-html="$options.filters.detailPolozky(row.nazev_polozky, row.id_polozky, 45)"></span> </td>
                                    <td class="text-center">{{ row.pocet_mj_celkem | formatNumber  }} {{ row.merna_jednotka }}</td>
                                    <td class="text-center">{{ row.pocet_mj_nove  | formatNumber }} {{ row.merna_jednotka }}</td>
                                    <td class="text-success text-center">{{ row.pocet_mj_pouzite  | formatNumber  }} {{ row.merna_jednotka }}</td>
                                </tr>
                                <skladove-zasoby-detail-pol-nd-polozky :key="keyWan[row.id_polozky]" table-cols="4" :id-polozky="row.id_polozky" :id-parkovaci-pozice="idParkovaciPozice" :show-detail="showDetail"></skladove-zasoby-detail-pol-nd-polozky>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div class="detail-regalu" v-else>
            <h2 class="text-danger text-center">Není možné zobrazit detail regálu</h2>
        </div>

    </div>
</template>

<script>
import axiosApiPostV2 from "@/router/axiosApiMethod";
import TheButton from "@/components/TheButton";
import SkladoveZasobyDetailPolNdPolozky from "@/views/content/detail/SkladoveZasobyDetailPolNdPolozky";

export default {
    name: "ParkovaciPozice",
    components: {SkladoveZasobyDetailPolNdPolozky, TheButton},
    data() {
        return {
            idParkovaciPozice: this.$route.params.id_pozice_regalu,
            detailParkovaciPozice: {},
            zatizenostPozice: {},
            skladoveZasoby: {},
            keyWan: {},
            showDetail: false,
            zobrazitRegal: false,
        }
    },
    created() {
        if (this.idParkovaciPozice != 'N' && this.idParkovaciPozice != 0)
        {
            let params = {
                id_pozice_regalu: this.idParkovaciPozice,
            };
            this.$root.$emit('showLoader', {show: true});
            axiosApiPostV2('detail-regalove-pozice', params, 'detail').then( result => {
                if (result != null && Object.keys(result).length > 0)
                {
                    let idSkladu = result.id_skladu;
                    let nosnost = result.nosnost;
                    this.detailParkovaciPozice = result;
                    axiosApiPostV2('regaly-obsazenost', {typ: 'pozice', id: this.idParkovaciPozice}, 'data').then( result => {
                        let barvaTextu = '';
                        if (result.pomer >= 90)
                        {
                            barvaTextu = 'text-bold';
                        }
                        if (result.pomer == 100 && result.obsazenost < nosnost)
                        {
                            result.pomer = 99;
                        }
                        if (result.pomer > 100 || result.obsazenost > nosnost)
                        {
                            barvaTextu = 'text-danger';
                        }
                        result.barva_textu = barvaTextu;
                        this.zatizenostPozice = result;

                        axiosApiPostV2('skladove-zasoby-parkovaci-pozice', {id_skladu: idSkladu, id_pozice_regalu: this.idParkovaciPozice, 'detail_polozky': true}, 'data').then( result => {
                            let keyWan = [];
                            $.each(result, function (key, value) {
                                let idPolozky = value.id_polozky;
                                keyWan[idPolozky] = idPolozky;
                                value.pocet_mj_celkem = value.pocet_mj_nove + value.pocet_mj_pouzite;
                            });
                            this.keyWan = keyWan;
                            this.skladoveZasoby = result;
                            this.$root.$emit('showLoader', {show: false});
                            this.zobrazitRegal = true;
                        });

                    });
                }
                else
                {
                    this.$root.$emit('showLoader', {show: false});
                    $.playSound(require('@/assets/sounds/cancel.mp3'));
                    this.zobrazitRegal = false;
                }
            });
        }
        else
        {
            $.playSound(require('@/assets/sounds/cancel.mp3'));
            this.zobrazitRegal = false;
        }
    },
    filters: {
        maxLength(value, max = 35)
        {
            return Helper.maxLength(value, max);
        },
        formatNumber(value) {
            return Helper.formatNumber(value);
        },
        decimalNumber(value) {
            return Helper.decimalNumber(value);
        },
        detailPolozky(value, idPolozky, max = 0)
        {
            return Helper.detailPolozky(value, idPolozky, max);
        },
        detailRegalu(value, idRegalu)
        {
            return Helper.detailRegalu(value, idRegalu);
        }
    },
    methods: {
        showDetailPolozky(idPolozky)
        {
            this.$root.$emit('showDetailPolozky', {show: true, id_polozky: idPolozky});
        },
        tiskStitku()
        {
            swal({
                className: 'select-arrows',
                title: "Vyberte směr šipky",
                text: '',
                icon: "",
                buttons: {
                    confirm: '•',
                    up: '↑',
                    down: '↓',
                    left: '←',
                    right: '→'
                },
            }).then((click) => {
                this.$root.$emit('showLoader', {show: true});
                let smerSipky = '';
                if (click == true)
                {
                    smerSipky = '-';
                }
                if (click == 'up')
                {
                    smerSipky = 1;
                }
                if (click == 'down')
                {
                    smerSipky = 3;
                }
                if (click == 'left')
                {
                    smerSipky = 4;
                }
                if (click == 'right')
                {
                    smerSipky = 2;
                }

                if (smerSipky != '')
                {
                    let params = {
                        smer: smerSipky,
                        id_parkovaci_pozice: this.idParkovaciPozice
                    };
                    axiosApiPostV2('tisk-regalove-pozice', params, 'result').then( result => {
                        this.$root.$emit('showLoader', {show: false});
                        this.$root.$emit('alertMessage', {text: 'Štítek na parkovací pozici byl vytisknut', color: 'success', autoHide: 3, strong: ''});
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .tisk-stitku {
        position: absolute;
        right: 10px;
    }

</style>